import Ajax from './Ajax.js'

const Stats = {
  getStats: () => {
    return Ajax.get('/stats');
  },
  getTheoreticalStats: () => {
    return Ajax.get('/theoretical-stats');
  },
  getAdminStats: () => {
    return Ajax.get('/admin-stats');
  }
}

export default Stats
