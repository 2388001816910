<template>
  <div class="home">
    <div id="home-top">
      <img alt="" src="@/assets/img/home-1.jpg">
      <div id="top-box">
        <div class="container d-flex align-items-md-center h-100 py-5 py-md-0">
          <div class="row d-flex justify-content-center align-items-center w-100">
            <div class="col-md-12 text-center">
              <img class="mb-4" src="@/assets/logo.png" style="width: 215px; max-width: 100%">
              <h1 class="mb-4">ZERTI CARBON</h1>
              <h2 class="mb-4">BLOCKCHAIN CERTIFIED CARBON SINK SOLUTIONS</h2>
              <button class="btn btn-primary btn-lg mb-5" @click="$router.push('/areas')">Find your
                trees
              </button>
              <transition name="fade">
                <div class="table-responsive pb-4 d-none d-sm-block">
                  <table id="home-table">
                    <tr>
                      <td>Forests</td>
                      <td>Total Farmed Ha</td>
                      <td>Total Trees</td>
                      <td>CCO2 Tons Farmed per Year</td>

                    </tr>

                    <tr>
                      <td>
                        <template v-if="stats">
                          {{ stats.areasQty }}
                        </template>
                        <LazyLoadingPlaceholder v-else/>
                      </td>
                      <td>
                        <template v-if="stats">
                          {{
                            new Intl.NumberFormat('de-DE', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }).format(stats.totalArea)
                          }} Ha
                        </template>
                        <LazyLoadingPlaceholder v-else/>
                      </td>
                      <td>
                        <template v-if="stats">
                          {{ new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(stats.totalTrees) }}
                        </template>
                        <LazyLoadingPlaceholder v-else/>
                      </td>
                      <td>
                        <template v-if="totalTheoreticalYearlyIncrement">
                          {{
                            new Intl.NumberFormat('de-DE', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }).format(totalTheoreticalYearlyIncrement)
                          }}
                        </template>
                        <LazyLoadingPlaceholder v-else/>
                      </td>
                    </tr>

                  </table>
                </div>
              </transition>
              <transition name="fade">
                <div class="row align-items-end pt-4 d-block d-sm-none">
                  <div v-if="stats" class="col-sm pb-5">
                    <h4 class="mb-3">Forests</h4>
                    <h3 class="font-weight-bold">
                      <template v-if="stats">
                        {{ stats.areasQty }}
                      </template>
                      <LazyLoadingPlaceholder v-else/>
                    </h3>
                  </div>
                  <div v-if="stats" class="col-sm pb-5">
                    <h4 class="mb-3">Total Farmed Ha</h4>
                    <h3 class="font-weight-bold">
                      <template v-if="stats">
                        {{
                          new Intl.NumberFormat('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(stats.totalArea)
                        }} Ha
                      </template>
                      <LazyLoadingPlaceholder v-else/>
                    </h3>
                  </div>
                  <div v-if="stats" class="col-sm pb-5">
                    <h4 class="mb-3">Total Trees</h4>
                    <h3 class="font-weight-bold">
                      <template v-if="stats">
                        {{ new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(stats.totalTrees) }}
                      </template>
                      <LazyLoadingPlaceholder v-else/>
                    </h3>
                  </div>
                  <div v-if="totalTheoreticalYearlyIncrement" class="col-sm pb-5">
                    <h4 class="mb-3">ZCO2 Farmed per Year</h4>
                    <h3 class="font-weight-bold">
                      <template v-if="totalTheoreticalYearlyIncrement">
                        {{
                          new Intl.NumberFormat('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(totalTheoreticalYearlyIncrement)
                        }}
                      </template>
                      <LazyLoadingPlaceholder v-else/>
                    </h3>
                  </div>
                </div>
              </transition>
              <h5 class="mb-4">Forestation · Digitalization · Monetization</h5>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>


import Stats from "../api/Stats";
import LazyLoadingPlaceholder from "@/components/Home/LazyLoadingPlaceholder.vue";

export default {
  name: 'Home',
  components: {
    LazyLoadingPlaceholder,
  },
  data() {
    return {
      stats: null,
      totalTheoreticalYearlyAcc: null,
      totalTheoreticalYearlyIncrement: null
    }
  },
  created() {
    Stats.getStats()
      .then((stats) => {
        this.stats = stats.data
      }).catch((error) => {
      console.log(error)
    })
    Stats.getTheoreticalStats()
      .then((stats) => {
        this.totalTheoreticalYearlyAcc = stats.data.totalTheoreticalYearlyAcc;
        this.totalTheoreticalYearlyIncrement = stats.data.totalTheoreticalYearlyIncrement;
      }).catch((error) => {
      console.log(error)
    })
  }
}
</script>

<style scoped>

</style>
