<template>
  <b-container class="admin py-5">
    <b-row>
      <b-col
        v-if="isWeb2UserConnected && getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'">
        <h1>Admin</h1>

        <b-card>
          <h3>Balance statistics</h3>
          <div class="table-responsive pb-4">
            <table class="table table-striped">
              <tr>
                <td>Forests</td>
                <td>Planted Trees</td>
                <td>Old Trees</td>
                <td>CCO2 Tons available</td>
              </tr>
              <tr>
                <td>{{
                    new Intl.NumberFormat('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(stats.areas)
                  }}
                </td>
                <td>{{
                    new Intl.NumberFormat('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(stats.plantedTrees)
                  }}
                </td>
                <td>{{
                    new Intl.NumberFormat('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(stats.oldTrees)
                  }}
                </td>
                <td>{{
                    new Intl.NumberFormat('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(stats.balance)
                  }}
                </td>
              </tr>
            </table>
          </div>
          <h3>Old forests historical (Estimation: {{ stats.totalEstimation }} CO2 Tons)</h3>
          <div class="table-responsive pb-4">
            <table class="table table-striped">
              <tr>
                <td>Scanning Month</td>
                <td>Years from scanning</td>
                <td style="text-align: right;">Forests</td>
                <td style="text-align: right;">Total Trees</td>
                <td style="text-align: right;">Estimation</td>
                <td style="text-align: right; display: none">CCO2 Tons available</td>
              </tr>
              <tr v-for="historicStat in stats.historicStats" v-bind:key="historicStat">
                <td>{{ historicStat.scanYear }}</td>
                <td>{{ (parseInt(moment().diff(moment(historicStat.scanYear, 'YYYY-MM'), 'month')) / 12).toFixed(1) }}
                </td>
                <td style="text-align: right;">{{
                    new Intl.NumberFormat('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(historicStat.areas)
                  }}
                </td>
                <td style="text-align: right;">{{
                    new Intl.NumberFormat('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(historicStat.totalTrees)
                  }}
                </td>
                <td style="text-align: right; display: none">{{
                    new Intl.NumberFormat('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }).format(historicStat.balance)
                  }}
                </td>
                <td style="text-align: right;">
                  {{
                    (parseInt(historicStat.totalTrees) * 0.012 * parseInt(moment().diff(moment(historicStat.scanYear, 'YYYY-MM'), 'month')) / 12).toFixed(1)
                  }}
                  CO2 Tons
                </td>
              </tr>
            </table>
          </div>


          <AreaAddComponent/>

          <!--          <hr />-->

          <!--          <TreeTypeAddComponent />-->

          <hr/>

          <CountryAddComponent/>
        </b-card>
      </b-col>
      <b-col v-else>
        <p>You should not be here!</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AreaAddComponent from "../components/Admin/AreaAddComponent"
import {mapGetters} from "vuex";
// import TreeTypeAddComponent from "../components/Admin/TreeTypeAddComponent";
import CountryAddComponent from "../components/Admin/CountryAddComponent";
import Stats from "../api/Stats";
import moment from "moment";

export default {
  name: 'Admin',
  data() {
    return {
      stats: null
    }
  },
  created() {
    Stats.getAdminStats()
      .then((stats) => {

        console.log(stats.data, 'stats.data')
        this.stats = stats.data
        let totalEstimation = 0;
        console.log('STARTTTTTTTTTTTTTTTTTTTT!!')
        console.log(this.stats.historicStats, 'this.stats.historicStats')
       this.stats.historicStats.map((historicStat) => {
          totalEstimation += parseInt(historicStat.totalTrees) * 0.012 * parseInt(moment().diff(moment(historicStat.scanYear, 'YYYY-MM'), 'month')) / 12
        })

        console.log({totalEstimation})

        this.stats.totalEstimation = (totalEstimation).toFixed(2);

      }).catch((error) => {
      console.log(error)
    })
  },
  components: {
    CountryAddComponent,
    // TreeTypeAddComponent,
    AreaAddComponent
  },

  computed: {
    moment() {
      return moment
    },
    ...mapGetters("user", ["isWeb2UserConnected", "getWeb2ActiveAccount"])
  }
}
</script>

<style scoped>

</style>
